import React from 'react';
import './App.css';
import { isForDev, S3_URL, API_URL, USE_DEMO_DATA } from './config';
import axios from 'axios';

function Login(props) {

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const username = form.username.value;
    const password = form.password.value;

    const payload = new FormData()
    payload.append('username', username)
    payload.append('password', password)
    if (USE_DEMO_DATA) {
      props.changePage('humanApproval', {username: username});
    }
    else {
      axios.post(`${API_URL}/api/signin/`, payload)
      .then(res => {
        console.log(res);
        props.changePage('humanApproval', {username: username});
      })
      .catch(err => {
        console.log(err);
      });
    }
  }

  const signUp = () => {
    const payload = {
      username: "wes_bottoms", password: '@.c\F9<`Eb|$zdLq(G8I',
      // username: "sam_harrison", password: 'q]d2sO[=+E0a#P(a!lU(',
      // username: "alex_whedon", password: '}-uK1aet]2:u+B2C)Y^"',
    };
    axios.post(`${API_URL}/api/signup/`, payload)
    .then(res => {
      console.log(res);
    })
    .catch(err => {
      console.log(err);
    });
  }

  


  return (
    <section className="ulo-login ulo-background-gradient-reverse">
      { isForDev &&
        <div className='ulo-dev-buttons'>
          *for dev*
          <button onClick={() => props.changePage('none')}>&lt;</button>
          <button onClick={() => props.changePage('humanApproval')}>&gt;</button>
        </div>
      }
      <img className="ulo-logo" src={`${S3_URL}/temptations-logo.png`} alt="Logo" />
      <h1>Login</h1>
      <form className="ulo-login-form" onSubmit={(e) => handleSubmit(e)}>
        <input className="ulo-login-input" type="text" name="username" placeholder="Username" />
        <input className="ulo-login-input" type="password" name="password" placeholder="Password" />
        <button className="ulo-btn ulo-btn-dark">Login →</button>
      </form>
      <br />
      {/* <button className="ulo-btn ulo-btn-dark" onClick={() => signUp()}>Sign Up</button> */}
    </section>
  );
};

export default Login;