import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import { isForDev, API_URL, USE_DEMO_DATA } from './config';
import axios from 'axios';
import { base64toBlob, readBlob } from './helpers';
import { LineWave } from 'react-loader-spinner';

const PLACEHOLDER_SUBMIT = ['','','',''];
const PLACEHOLDER_STYLES = {
  "ph0":[{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false}],
  "ph1":[{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false}],
  "ph2":[{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false}],
  "ph3":[{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false},{photo:'',photoUrl:'',selected:false}],
};

const DEMO_DATA = {
  // ... fill in later
};

const createImageUrl = async (binaryData) => {
  if (!binaryData) {
    console.log('No binary data provided');
    return '';
  }
  
  if (typeof binaryData === 'string') {
    if (binaryData.startsWith('http')) {
      console.log('URL detected:', binaryData);
      return binaryData;
    }
    // Handle base64 string if that's how it's coming
    try {
      const base64Data = binaryData.split(',')[1] || binaryData;
      const binaryString = atob(base64Data);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: 'image/jpeg' });
      console.log('Created blob from base64:', blob);
      return URL.createObjectURL(blob);
    } catch (e) {
      console.error('Error processing string data:', e);
    }
  }

  // Handle binary data
  try {
    console.log('Processing binary data:', binaryData);
    const blob = new Blob([binaryData], { type: 'image/jpeg' });
    console.log('Created blob from binary:', blob);
    return URL.createObjectURL(blob);
  } catch (e) {
    console.error('Error creating blob:', e);
    return '';
  }
};

const Refresh = (props) => {
  return (
    <button className="ulo-refresh ulo-btn ulo-btn-dark" onClick={() => props.onClick()}>
      <div className='ulo-referesh-img'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" fill="#FFFFFF" />
        </svg>
      </div>
    </button>
  )
};

const ConfirmRejection = (props) => {
  return ReactDOM.createPortal(
    <div className="ulo-confirm-rejection">
      <div className="ulo-confirm-rejection-content">
        <p>Are you sure you want to reject all generated images in {props.skippedStyles.length > 1 ? 'these styles' : 'this style'}?</p>
        <div className="ulo-skipped-styles">
          {props.skippedStyles.map((style, i) => (
            <div key={`skipped-style-${i}`} className="ulo-skipped-style">{style}{i < props.skippedStyles.length - 1 && props.skippedStyles.length > 1 ? ',' : ''}</div>
          ))}
        </div>
        <div className="ulo-button-wrapper">
          <button className="ulo-btn ulo-btn-light" onClick={() => props.onCancel()}>Cancel</button>
          <button className="ulo-btn ulo-btn-dark" onClick={() => props.onConfirm()}>Confirm</button>
        </div>
      </div>
    </div>,
    document.getElementById('root')
  );
};

function HumanApproval(props) {
  const [submittedPhotos, setSubmittedPhotos] = useState([]);
  const [currentUserSubmissionID, setCurrentUserSubmissionID] = useState(-1);
  const [queueSize, setQueueSize] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [skippedStyles, setSkippedStyles] = useState([]);
  const [submitStyles, setSubmitStyles] = useState({});
  const [loading, setLoading] = useState(false);
  const [styles, setStyles] = useState({});
  const [styleSkeleton, setStyleSkeleton] = useState(true);

  useEffect(() => {
    return () => {
      // Cleanup any existing Blob URLs
      Object.values(styles).forEach(stylePhotos => {
        stylePhotos.forEach(photo => {
          if (photo.photoUrl && photo.photoUrl.startsWith('blob:')) {
            URL.revokeObjectURL(photo.photoUrl);
          }
        });
      });
      submittedPhotos.forEach(photo => {
        if (photo && photo.startsWith('blob:')) {
          URL.revokeObjectURL(photo);
        }
      });
    };
  }, [styles, submittedPhotos]);

  const processData = async (data) => {
    console.log('processData data:', data);
    setQueueSize(USE_DEMO_DATA ? 1 : data.queue);
    
    if ((USE_DEMO_DATA && data.submitted_photos.length === 0) || (!USE_DEMO_DATA && data.queue === 0)) {
      setSubmittedPhotos(PLACEHOLDER_SUBMIT);
      setStyles(PLACEHOLDER_STYLES);
      setStyleSkeleton(true);
      setCurrentUserSubmissionID(-1);
    } else {
      // Handle submitted photos
      const processedSubmittedPhotos = await Promise.all(
        data.submitted_photos.map(async (photo) => {
          return await createImageUrl(photo.photo);
        })
      );
      setSubmittedPhotos(processedSubmittedPhotos);
  
      // Handle generated photos
      const art_styles = {};
      for (const photo of data.generated_photos) {
        if (!(photo.style in art_styles)) {
          art_styles[photo.style] = [];
        }
        
        const photoUrl = await createImageUrl(photo.file.photo);

        console.log('photo for art_styles:', photo);
        art_styles[photo.style].push({
          photo: photoUrl,
          photoUrl: photoUrl,
          selected: false,
          id: photo.id
        });
      }
      
      setStyles(art_styles);
      setStyleSkeleton(false);
      setCurrentUserSubmissionID(data.user_submission_id);
    }
  };

  const queueUpdate = async () => {
    setLoading(true);
    try {
      if (USE_DEMO_DATA) {
        await processData(DEMO_DATA);
      } else {
        const res = await axios.get(`${API_URL}/api/review/`, {
          params: { username: props.username },
          responseType: 'json'
        });
        console.log('this is the review data:', res.data);
        await processData(res.data);
      }
    } catch (err) {
      console.error('Error updating queue:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    const allSubmitStyles = {};
    const allSkippedStyles = [];
    for (const [key, value] of Object.entries(styles)) {
      allSubmitStyles[key] = [];
      let styleSkipped = true;
      for (const photo of value) {
        if (photo.selected) {
          styleSkipped = false;
        }
        console.log('photo for allSubmitStyles:', photo);
        allSubmitStyles[key].push({photoUrl: photo.photoUrl, photoId: photo.id, selected: photo.selected});
      }
      if (styleSkipped) {
        allSkippedStyles.push(key);
      }
    }
    if (allSkippedStyles.length > 0) {
      setSkippedStyles(allSkippedStyles);
      setSubmitStyles(allSubmitStyles);
      setShowModal(true);
      return;
    }

    submitReview(allSubmitStyles);
  };

  // const submitReview = async (submitStyles) => {
  //   setLoading(true);
  //   const payload = {
  //     user_submission_id: currentUserSubmissionID,
  //     styles: submitStyles,
  //   };

  //   try {
  //     if (USE_DEMO_DATA) {
  //       await new Promise(resolve => setTimeout(resolve, 1000));
  //       await queueUpdate();
  //     } else {
  //       await axios.post(`${API_URL}/api/review/`, payload, {
  //         withCredentials: true
  //       });
  //       await queueUpdate();
  //     }
  //   } catch (err) {
  //     console.error('Error submitting review:', err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const submitReview = async (submitStyles) => {
  //   setLoading(true);
  //   const payload = {
  //     user_submission_id: currentUserSubmissionID,
  //     styles: submitStyles,
  //   };
  
  //   try {
  //     if (USE_DEMO_DATA) {
  //       await new Promise(resolve => setTimeout(resolve, 1000));
  //       await queueUpdate();
  //     } else {
  //       // Convert the payload to a URL-safe string
  //       const encodedData = encodeURIComponent(JSON.stringify(payload));
        
  //       await axios.get(`${API_URL}/api/review/`, {
  //         params: {
  //           action: 'approve',
  //           data: encodedData,
  //           username: props.username
  //         }
  //       });
        
  //       await queueUpdate();
  //     }
  //   } catch (err) {
  //     console.error('Error submitting review:', err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const submitReview = async (submitStyles) => {
    setLoading(true);
    try {
      if (USE_DEMO_DATA) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        await queueUpdate();
      } else {
        console.log('Starting review submission...');
        console.log('Submit styles:', submitStyles);
  
        // Build a simplified query string with just IDs and approval status
        const approvals = Object.entries(submitStyles).map(([style, photos]) => {
          console.log(`Processing style: ${style}`);
          return photos.map((photo) => {
            console.log('Processing photo:', photo);
            return `${photo.photoId}:${photo.selected}`;
          }).join(',');
        }).join(';');
  
        console.log('Final approvals string:', approvals);
        console.log('Making API call with params:', {
          action: 'approve',
          username: props.username,
          user_submission_id: currentUserSubmissionID,
          approvals: approvals
        });

        console.log('approvals:', approvals);
  
        // const response = await axios.get(`${API_URL}/api/review/`, {
        //   params: {
        //     action: 'approve',
        //     username: props.username,
        //     user_submission_id: currentUserSubmissionID,
        //     approvals: approvals
        //   }
        // });
        const response = await axios.post(`${API_URL}/api/review/`, {
          user_submission_id: currentUserSubmissionID,
          approvals: approvals,
          username: props.username
        });
  
  
        console.log('API response:', response);
        await queueUpdate();
      }
    } catch (err) {
      console.error('Error submitting review:', err);
    } finally {
      setLoading(false);
    }
  };

  const toggleSelect = (styleIndex, photoIndex) => {
    setStyles(m => ({
      ...m,
      [styleIndex]: m[styleIndex].map((photo, pIndex) => ({
        ...photo,
        selected: pIndex === photoIndex ? !photo.selected : false
      }))
    }));
  };

  const handleModal = (option) => {
    setShowModal(false);
    setSkippedStyles([]);
    if (option === 'confirm') {
      submitReview(submitStyles);
      setSubmitStyles({});
    }
  };

  useEffect(() => {
    queueUpdate();
  }, []);

  return (
    <>
      {isForDev &&
        <div className='ulo-dev-buttons'>
          *for dev*
          <button onClick={() => props.changePage('login')}>&lt;</button>
          <button onClick={() => props.changePage('none')}>&gt;</button>
        </div>
      }
      <div className="ulo-human-approval ulo-background-gradient-reverse">
        <header className="ulo-header">
          <div className="ulo-header-title">
            <h1>Choose a photo for each style {isForDev ? currentUserSubmissionID : ""}</h1>
          </div>
          <div className="ulo-header-queue">
            <p><b>{queueSize} Submissions Left</b><br />in Queue</p>
          </div>
        </header>
        <main className="ulo-desktop-wrapper">
          <aside className="ulo-submitted-photos">
            <p className='ulo-btn ulo-human-approval-btn'>Submitted photos</p>
            <div className='ulo-submitted-photos-wrapper'>
              {submittedPhotos.map((submittedPhoto, i) => (
                <div key={`submitted_photos_${i}`} className='ulo-photo-container'>
                  <div className='ulo-photo-window'>
                    {submittedPhoto && (
                      <img 
                        src={submittedPhoto} 
                        alt="submitted img" 
                        onError={(e) => {
                          console.error('Submitted image failed to load:', e);
                          e.target.style.display = 'none';
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </aside>
          <div className='ulo-style-wrapper'>
            {Object.entries(styles).map((style) => (
              <div key={style[0]} className="ulo-style-section">
                {!styleSkeleton && <p className='ulo-btn'>{style[0]} <br /> Style</p>}
                <div className="ulo-image-grid">
                  {style[1].map((photo, photoIndex) => (
                    <div
                      key={`generated-image-${photoIndex}`}
                      className={`ulo-photo-container ${photo.selected ? 'ulo-selected' : ''}`}
                      onClick={() => toggleSelect(style[0], photoIndex)}
                    >
                      <div className='ulo-photo-window'>
                        {!styleSkeleton && (
                          <>
                            <img 
                              src={photo.photoUrl} 
                              alt={`${style[0]} style`}
                              onError={(e) => {
                                console.error('Generated image failed to load:', e);
                                e.target.style.display = 'none';
                              }}
                            />
                            {photo.selected && (
                              <div className="ulo-checkmark-container">
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="18" cy="18" r="17" fill="#FFEF86" fillOpacity="0.2" stroke="white" strokeWidth="2" />
                                  <path d="M11 20L14.5 23.5L26 12" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </main>
        <footer className="ulo-button-wrapper">
          <button className="ulo-btn ulo-btn-dark" onClick={() => handleSubmit()}>
            <div>Submit →</div>
            {loading && <LineWave />}
          </button>
          {isForDev && <Refresh onClick={queueUpdate} />}
        </footer>
      </div>
      {showModal && (
        <ConfirmRejection
          onCancel={() => handleModal('cancel')}
          onConfirm={() => handleModal('confirm')}
          skippedStyles={skippedStyles}
          submitStyles={submitStyles}
          setLoading={setLoading}
        />
      )}
    </>
  );
}

export default HumanApproval;