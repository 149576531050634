export const photoUrl = "https://s3-alpha-sig.figma.com/img/6eb3/0690/36b2066f312d3a799e40bb5477c3cde8?Expires=1709510400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=e~cRc2EH50GEVg~uAe5yRoilgNfAyECO8TDoKbgIVT8m~W~Kq77dnPDbLVGZbHhlad9otFVH1D2-TRf7Z9sKlFydwidB9LJrkaOG94TMnqnNxPisN5FwTsPuUUYFnhKNXUdzNpcbTfuLZqtGIg1lgvXxtL3eFhZCJz5-UH6RE-Gqxaz1LPs8I93YCawvXPljICh6rdLqU9LRLHC761qU8PwPObHrNEtw33re~2oqtIJe8J-TFPuUu2KIpGGGfSTtCHm7UKZ94Z5Jm5fRXQ9TzaYEj4ERSSQkUYbv-sZzrcjVfYybY-nAHVH3XFgILCzdRoOPtUGzmfY5I~SL7q9i-g__"

export const logo = "https://s3-alpha-sig.figma.com/img/00ee/490e/6dc844188ff7cc7492ceda486be4c544?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=hI29oazvcKtWvPvlnfgw47Pc17ZCzeRIfd0HH2i22t0LqMTUXoQU4imUvIW69h0HR-7ENzpgEb7fpmS3WHrFngBQmXrxIKAuw4~eXnGZz3SP8HmiMb~afzavvSURV--A7NF~UaV1~UN9X3cNj~GncmXzMFxCNYKOYEESdgvUA2NaNgrUi742m~jYoWd8f1WaMss6gqQfN4ubY5yzHRPXQH9khxLy2DcD-cChJTmrav0YVx-12lCVNcQioIjvZo9c1rFJ0V0B4K-80sNRyykZ1pMwaMOMNMlm-Dibvhi79xi5ATXJFVV16Hbn2TQQUoy-tvbltPjivGkJ2uSU2lGyOQ__"

export const backgroundImg = "https://s3-alpha-sig.figma.com/img/888b/5eb6/6876e03fb0422636873f3e270d89e8ec?Expires=1709510400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=p5vDT-pZ9KBpZHMuOINWTUZsMWhOWYoIGGiAcnPDUvKnslTtu17kFMuDDclS2vg7K0Fv3dHRXmTGzYERxO-CUTKkyVK0hYWF3eX9CVxJfvndtOB9wvnKdU4yje2AcyQOIIWhg8m~8N9ktv1tWxd5ADdI7H3AjuW~l6mxGzAaMLFWPpYCyy9l6akN-EjJCPDITbtgv-1mKbwWhxItnUAjemuTSOnsJosSds-KAizEZcLHNkhn68bXEN2DeH8Av2yJy1tHacYG4zNNGP19AVgCA-2-JJxH1PSL5o1VaaASYsuMUulkSrGhOgcFfp8EedM3f0VrL1o8Er-ce0brBv6NMQ__"

export const S3_URL = 'https://temptations-pet-portrait-public.s3.amazonaws.com';

// export const API_URL = 'http://localhost:8001';
// export const API_URL = 'http://54.175.148.97';
export const API_URL = 'https://stage.userbackend.temptationstreats.com';
// export const API_URL = 'https://stage.imagebackend.temptationstreats.com';

export const isForDev = false;

export const isDeployedAsHumanApproval = true;

export const USE_DEMO_DATA = false; // Set this to false to use the real backend